

// Libaries
import { Vue, Component } from 'nuxt-property-decorator';
import {Products } from '~/operations/messages';

// Components
import TransformNavigationMenu from '~/components/organisms/nav/TransformNavigationMenu.vue';

type TransformMenuItems = {
  name: string;
  title: string;
  link: { path: string };
  externalLink: boolean;
};

@Component({
  scrollToTop: true,
  components: {
    TransformNavigationMenu,
  },
})
export default class HeizoelTankIndex extends Vue {

  Products = Products;

  transformMenuItems: Array<TransformMenuItems> = [
    {
      name: `${Products.ePeilstab}`,
      title: `Informationen zu unserer ${Products.ePeilstab} Messtechnik`,
      link: { path: '/heizoeltank/e-peilstab' },
      externalLink: false,
    },
    {
      name: `${Products.betriebsanleitungen}`,
      title:'Laden Sie die e-Peilstab plus Betriebsanleitung herunter',
      link: { path: '/heizoeltank/betriebsanleitungen'},
      externalLink:false
    },
    {
      name: `${Products.tPeilstab}`,
      title: `Informationen zu unserer ${Products.tPeilstab} Messtechnik`,
      link: { path: '/heizoeltank/t-peilstab' },
      externalLink: false,
    },
    {
      name: 'Tankreinigung',
      title: 'Erfahren Sie worauf Sie bei der Tankreinigung achten sollten',
      link: { path: '/heizoeltank/tankreinigung' },
      externalLink: false,
    },
    {
      name: 'Peiltabellen-Rechner',
      title: 'Finden Sie die passende Peiltabelle zu Ihrem Heizöltank',
      link: { path: '/heizoeltank/peiltabellen' },
      externalLink: false,
    },
    {
      name: 'Fachbetriebe',
      title: 'Finden Sie Fachbetriebe in Ihrer Nähe',
      link: { path: '/heizoeltank/fachbetriebe' },
      externalLink: false,
    },
  ];

  getActiveTabName(): string {
    return this.transformMenuItems.filter(ele => {
      return this.$route.fullPath.includes(ele.link.path);
    })[0].name;
  }

}

