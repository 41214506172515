
import { Component, Prop, Vue, Watch } from "nuxt-property-decorator";
import RouterLink from "~/components/atoms/text/RouterLink.vue";

@Component({
  components: {
    RouterLink,
  },
})
export default class TransformMenu extends Vue {
  @Prop({ required: true }) menuItems: Array<{
    link: string;
    title: string;
    name: string;
  }>;
  @Prop({ default: "Menu" }) selectedOption: Array<{}>;

  menu = {
    state: false,
  };

  option = this.getSelectedOption;

  toggleSelectOptionsVisibility() {
    this.menu.state = !this.menu.state;
  }

  get getSelectedOption() {
    return this.selectedOption;
  }

  @Watch("selectedOption")
  selectedOptionWatcher(newVal) {
    this.option = newVal;
  }

  selectOption(optionName) {
    this.option = optionName;
    this.menu.state = !this.menu.state;
  }

  valuesAreSame(string1, string2) {
    if (string1 === string2) return true;
    return false;
  }
}
